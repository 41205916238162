<template>
  <div class="full-container">
    <top-nav
      :banner="globalData.officialVehicle.banner"
      color="#333333"
      isParent
    >
    </top-nav>

    <div class="industry content-limit">
      <block-title
        :en="globalData.officialVehicle.industry.enTitle"
        :title="globalData.officialVehicle.industry.chTitle"
        :sub="globalData.officialVehicle.industry.chBrief"
      ></block-title>
      <div class="tabs">
        <div
          class="item"
          :class="{ active: industryCurrentIndex === index }"
          v-for="(item, index) of globalData.officialVehicle.industry.list"
          :key="index"
          @click="industryCurrentIndex = index"
        >
          {{ item.title }}
        </div>
      </div>
      <div
        class="content"
        v-for="(item, index) of globalData.officialVehicle.industry.list"
        :key="index"
        v-if="industryCurrentIndex === index"
      >
        <img class="img" :src="item.img" alt="" />
        <div class="hover">
          <div class="title">
            <img
              class="icon"
              src="/static-images/officialVehicle/img_1.png"
              alt=""
            />
            {{ item.title }}
          </div>
          <div class="brief">{{ item.brief }}</div>
        </div>
      </div>
    </div>

    <div
      class="plan"
      :style="{
        background: `url(${globalData.officialVehicle.plan.bg}) no-repeat center/cover`,
      }"
    >
      <block-title
        :en="globalData.officialVehicle.plan.enTitle"
        :title="globalData.officialVehicle.plan.chTitle"
        :sub="globalData.officialVehicle.plan.chBrief"
      ></block-title>
      <div class="list content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.officialVehicle.plan.list"
          :key="index"
        >
          <img class="icon" :src="item.img" alt="" />
          <div class="title">{{ item.title }}</div>
          <div class="brief">{{ item.brief }}</div>
        </div>
      </div>
    </div>

    <div class="resolution">
      <block-title
        :en="globalData.officialVehicle.application.enTitle"
        :title="globalData.officialVehicle.application.chTitle"
        :sub="globalData.officialVehicle.application.chBrief"
      ></block-title>
      <div class="content content-limit" style="margin-top: 60px">
        <div class="left">
          <div
            class="item"
            :class="{ 'active-item': index === resCurrentIndex }"
            v-for="(item, index) of globalData.officialVehicle.application.list"
            :key="index"
          >
            <div class="top" @click="resCurrentIndex = index">
              <img
                class="icon"
                v-if="resCurrentIndex !== index"
                :src="item.icon"
                alt=""
              />
              <img
                class="icon"
                v-if="resCurrentIndex === index"
                :src="item.activeIcon"
                alt=""
              />
              {{ item.title }}
            </div>
            <div class="bottom">
              {{ item.brief }}
            </div>
          </div>
        </div>
        <div class="right">
          <video
            autoplay
            v-if="
              globalData.officialVehicle.application.list[resCurrentIndex]
                .isVideo
            "
            class="img"
            :src="
              globalData.officialVehicle.application.list[resCurrentIndex].img
            "
          ></video>
          <img
            v-else
            class="img"
            :src="
              globalData.officialVehicle.application.list[resCurrentIndex].img
            "
            alt=""
          />
          <div class="feature">
            <div
              class="item"
              v-for="(item, index) of globalData.officialVehicle.application
                .list[resCurrentIndex].feature"
              :key="index"
            >
              <img class="icon" src="@/asset/index/csb_index_61.png" alt="" />
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="feature"
      :style="{
        background: `url(${globalData.officialVehicle.feature.bg}) no-repeat center/cover`,
      }"
    >
      <div class="list content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.officialVehicle.feature.list"
          :key="index"
        >
          <div class="left">
            <div class="en">{{ item.enTitle }}</div>
            <div class="title">{{ item.title }}</div>
            <div
              class="option"
              v-for="(iitem, iindex) of item.options"
              :key="iindex"
            >
              {{ iitem }}
            </div>
          </div>
          <img class="right" :src="item.img" alt="" />
        </div>
      </div>
    </div>

    <product-list></product-list>

    <customer-wall></customer-wall>
    <footer-resolution></footer-resolution>
    <my-footer></my-footer>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import contactUs from "./index-view/contact-us.vue";
import customerWall from "./index-view/customer-wall.vue";
import supportService from "./index-view/support-service.vue";
import honorsWall from "./index-view/honors-wall.vue";
import productCenter from "./index-view/product-center.vue";

import displayicon1 from "../asset/index/csb_index_22.png";
import displayicon2 from "../asset/index/csb_index_23.png";
import displayicon3 from "../asset/index/csb_index_24.png";
import displayicon4 from "../asset/index/csb_index_25.png";

let plans = [];

export default {
  components: {
    contactUs,
    customerWall,
    supportService,
    honorsWall,
    productCenter,
  },
  data() {
    return {
      industryCurrentIndex: 0,

      resCurrentIndex: 0,
      display: [displayicon1, displayicon2, displayicon3, displayicon4],

      applyCurrentIndex: 0,
      abCurrentIndex: 0,
      driveCurrentIndex: 0,
      swiperCurrentIndex: 0,
      tabIndex: 0,
    };
  },
  methods: {
    switchTabs(index) {
      this.tabIndex = index;
    },
    next() {
      if (this.swiperCurrentIndex < this.swiper?.length - 1) {
        this.swiperCurrentIndex++;
      } else {
        this.swiperCurrentIndex = 0;
      }
    },
    sendInfo() {
      this.$router.push({
        name: "get-plan",
        params: {
          name: this.$refs.name.value,
          mobile: this.$refs.phone.value,
          plan: plans[this.applyCurrentIndex],
        },
      });
    },
  },
  computed: {
    ...mapGetters(["globalData"]),
  },
  mounted() {
    // plans = this.globalData.index.apply.tabs.map((v) => {
    //   return v.txt;
    // });
  },
  destroyed() {},
};
</script>

<style scoped lang="scss">
.display {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 60px 0 80px;
  .item {
    box-shadow: 3px 3px 8px 0px #e8e8e8;
    border-radius: 2px 2px 2px 2px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    line-height: 1;
    padding: 24px 0;
    .icon {
      width: 32px;
    }
    .info {
      .title {
        margin-bottom: 20px;
        color: #333333;
        font-size: 24px;
      }
      .brief {
        color: #666666;
        font-size: 14px;
      }
    }
  }
}
.about {
  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    background: url(@/asset/index/csb_index_29.png) no-repeat center/cover;
    height: 530px;
    line-height: 1;
    .en {
      font-size: 48px;
      color: rgba(255, 255, 255, 0.4);
    }
    .title {
      font-size: 48px;
      color: white;
      font-family: YouSheBiaoTiHei;
    }
  }
  .bottom {
    background: url(@/asset/index/csb_index_27.png) no-repeat -30px 20px/510px,
      url(@/asset/index/csb_index_27.png) no-repeat right -100px bottom / 510px;
    display: flex;
    padding-bottom: 30px;

    .content {
      width: 1080px;
      margin: 0 auto;
      padding: 50px 80px;
      border-radius: 20px;
      margin-top: -100px;
      background: white;
      box-sizing: border-box;
      .digit {
        display: flex;
        align-items: center;
        .item {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1;
          gap: 16px;
          &:first-child ~ .item {
            border-left: 1px solid #c4c4c4;
          }
          .icon {
            width: 60px;
          }
          .info {
            .name {
              font-size: 16px;
              color: #282938;
              margin-bottom: 10px;
            }
            .number {
              font-size: 36px;
              color: #096dd9;
            }
          }
        }
      }
      .title {
        font-size: 24px;
        color: #666666;
        margin-top: 58px;
        margin-bottom: 16px;
      }
      .txt {
        font-size: 16px;
        color: #666666;
        line-height: 2;
      }
      .strip {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;
        color: #455a64;
        font-size: 52px;
        background-color: rgba(196, 196, 196, 0.2);
        padding: 45px 0;
        margin-top: 30px;
        .icon {
          width: 48px;
        }
      }
    }
  }
}
.tech {
  padding: 80px 0;
  background: url(@/asset/index/csb_index_30.png) no-repeat center/cover;
  .list {
    margin-top: 80px;
    .item {
      display: flex;
      align-items: center;
      gap: 100px;
      padding: 100px;
      background: white;
      &:nth-child(2n) {
        background: transparent;
        flex-direction: row-reverse;
      }
      .info {
        flex-grow: 1;
        .en {
          font-size: 18px;
          color: #dddddd;
        }
        .title {
          font-size: 32px;
          color: #333333;
          font-weight: bold;
          margin-top: 8px;
        }
        .brief {
          font-size: 18px;
          color: #666666;
          line-height: 2;
          margin-top: 40px;
        }
      }
      .img {
        flex-shrink: 0;
        width: 460px;
      }
    }
  }
}
.prod-display {
  padding: 80px 140px 0;

  .prod-tabs {
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .prod-item {
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 24px;
      color: #666666;
      cursor: pointer;

      .prod-icon {
        height: 32px;
        margin-right: 16px;
      }

      .prod-txt {
        padding: 0 5px;
        box-sizing: border-box;
      }

      &:hover {
        color: #096dd9;
      }
    }

    .prod-active {
      color: #096dd9;
      flex-wrap: wrap;

      .prod-icon {
        height: 80px;
        margin-right: 0;
      }

      .prod-txt {
        width: 100%;
        margin-top: 16px;
      }
    }
  }

  .prod-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    // justify-content: space-between;
    margin-top: 80px;
    gap: 25px;

    .prod-item {
      width: calc(33% - 25px);
      box-sizing: border-box;
      padding: 20px;
      background-color: white;
      cursor: pointer;
      box-shadow: 0 0 10px 0 #eee;
      border-radius: 8px;

      .prod-inner {
        position: relative;

        .prod-img {
          width: 100%;
          height: 440px;
        }

        .prod-hover {
          position: absolute;
          border-radius: 8px;
          width: calc(100% - 32px);
          left: 16px;
          bottom: 16px;
          font-size: 20px;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1.5;
          transition: all 0.5s;
          font-size: 18px;
          color: #666666;

          .prod-innerTxt {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        &:hover {
          .prod-hover {
            opacity: 0.8;
            height: 40%;
            background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
            color: white;
            font-size: 20px;

            .prod-innerTxt {
              white-space: normal;
            }
          }
        }
      }
    }
  }
}
.product {
  padding: 100px 0 80px;
}
.prod-button {
  font-size: 24px;
  color: #fffdfd;
  background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
  border-radius: 2px 2px 2px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  margin: 0 auto;
  margin-top: 40px;
  width: 217px;
  cursor: pointer;
  gap: 12px;
  .icon {
    width: 30px;
  }
}
.issue {
  background: url(@/asset/index/csb_index_35.png) no-repeat center/cover;
  padding: 80px 0 140px;
  .center {
    position: relative;
    margin: 0 auto;
    width: 795px;
    margin-top: 140px;
    .bg {
      width: 100%;
    }
    .item {
      position: absolute;
      text-align: center;
      white-space: nowrap;
      .title {
        font-size: 24px;
        color: #333333;
        line-height: 1.5;
      }
      .brief {
        font-size: 14px;
        color: #666666;
        line-height: 2;
      }
    }
    .issue1 {
      left: 0;
      top: -15px;
    }
    .issue2 {
      right: 0;
      top: -15px;
    }
    .issue3 {
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
    .issue4 {
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
    .issue5 {
      left: 0;
      bottom: -32px;
    }
    .issue6 {
      right: 0;
      bottom: -32px;
    }
  }
}
.resolution {
  padding: 80px 0 100px;
  .content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 55px;
  }
  .left {
    width: 4px;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(78, 153, 253, 0.05) 0%,
      #4e99fd 55%,
      rgba(84, 130, 250, 0.05) 100%
    );
    position: relative;
    padding: 24px 0;
    .item {
      width: 432px;
      margin-left: -16px;
      line-height: 1;
      max-height: 32px;
      overflow: hidden;
      transition: all 0.3s;
      &:first-child ~ .item {
        margin-top: 40px;
      }
      .top {
        display: flex;
        align-items: center;
        gap: 24px;
        font-size: 20px;
        color: #6f8cab;
        cursor: pointer;
        .icon {
          width: 32px;
        }
      }
      .bottom {
        padding-left: 56px;
        padding-top: 20px;
        font-size: 20px;
        color: #666666;
        line-height: 2;
      }
    }
    .active-item {
      transition: all 0.5s;
      max-height: 500px;
      .top {
        color: #096dd9;
        font-weight: bold;
      }
      .bottom {
      }
    }
  }
  .right {
    margin-top: 24px;
    width: 737px;
    background: linear-gradient(
      180deg,
      rgba(9, 109, 217, 0.1) 0%,
      rgba(9, 109, 217, 0) 100%
    );
    border-radius: 8px 8px 8px 8px;
    padding: 24px;
    box-sizing: border-box;
    .img {
      width: 100%;
    }
    .feature {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        font-size: 24px;
        color: rgba(102, 102, 102, 1);
        margin-top: 40px;
        .icon {
          width: 24px;
        }
      }
    }
  }
}

.combine {
  padding: 100px 0;
  .content {
    position: relative;
    height: 400px;
    margin-top: 170px;
    .inner {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      margin-top: -70px;
      .list {
        box-sizing: border-box;
        margin-bottom: 20px;
        padding: 60px 0;
        box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.12);
        border-radius: 8px 8px 8px 8px;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 446px;
        height: 420px;
        background-color: rgba(255, 255, 255, 0.9);
        gap: 40px 0;
        .item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50%;
          gap: 4px;
          font-size: 24px;
          color: #333333;
          .icon {
            width: 24px;
          }
        }
      }
      .tabs {
        box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.16);
        border-radius: 8px 8px 8px 8px;
        display: flex;
        align-items: center;
        background-color: white;
        .tab {
          flex: 1;
          height: 60px;
          border-radius: 8px;
          font-size: 20px;
          color: #666666;
          width: 640px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .tab-active {
          background: linear-gradient(180deg, #4e99fd 0%, #3384e8 100%);
          color: white;
          transition: all 0.5s;
        }
      }
    }
  }
}
.system {
  padding: 80px 0;
  .content {
    margin-top: 100px;
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      &:first-child ~ .row {
        margin-top: 50px;
      }
      .item {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 18px;
        color: #333333;
        &:hover {
          color: #4e99fd;
        }
        .icon {
          width: 18px;
        }
      }
    }
    .row1,
    .row6 {
      width: 824px;
    }
    .row2,
    .row5 {
      width: 1020px;
    }
    .row3,
    .row4 {
      width: 1110px;
    }
  }
}
.industry {
  padding: 100px 0 80px;
  .tabs {
    display: flex;
    align-items: center;
    background: linear-gradient(122deg, #ffffff 0%, #f3f8ff 100%);
    box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.16);
    border-radius: 8px 8px 8px 8px;
    margin-bottom: 16px;
    margin-top: 80px;
    .item {
      font-size: 20px;
      color: #666666;
      flex: 1;
      cursor: pointer;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .active {
      color: white;
      background: linear-gradient(180deg, #4e99fd 0%, #3384e8 100%);
      border-radius: 8px 8px 8px 8px;
    }
  }
  .content {
    position: relative;
    font-size: 0;
    width: 100%;
    height: 400px;
    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .hover {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      text-align: center;
      background: linear-gradient(
        to top,
        #ffffff 0%,
        rgba(255, 255, 255, 0.1) 100%
      );
      box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.16);
      border-radius: 8px 8px 8px 8px;
      padding: 20px 0;
      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-size: 28px;
        color: #333333;
        .icon {
          width: 28px;
        }
      }
      .brief {
        color: rgba(102, 102, 102, 0.7);
        font-size: 18px;
        margin-top: 16px;
      }
    }
  }
}

.plan {
  padding: 80px 0;
  .list {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: 60px;
    .item {
      border-radius: 4px 4px 4px 4px;
      padding: 20px 30px 30px;
      flex: 1;
      height: 222px;
      box-sizing: border-box;
      border: 1px solid;
      text-align: center;
      border-image: linear-gradient(
          180deg,
          rgba(78.00000295042992, 153.00000607967377, 253.0000001192093, 1),
          rgba(84.00000259280205, 130.0000074505806, 250.00000029802322, 1)
        )
        1 1;
      .icon {
        width: 60px;
      }
      .title {
        color: rgba(51, 51, 51, 1);
        font-size: 24px;
        margin-top: 8px;
        margin-bottom: 12px;
      }
      .brief {
        font-size: 16px;
        color: rgba(51, 51, 51, 0.7);
        line-height: 2;
      }
    }
  }
}

.feature {
  padding: 80px 0;
  .list {
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 80px;
      background-color: white;
      padding: 100px 72px 100px 128px;
      &:nth-child(2n) {
        background-color: transparent;
        flex-direction: row-reverse;
        justify-content: center;
      }
      .left {
        .en {
          font-size: 18px;
          color: rgba(221, 221, 221, 1);
          font-family: Source Han Sans CN, Source Han Sans CN;
          margin-bottom: 8px;
          font-weight: bold;
        }
        .title {
          font-size: 32px;
          color: rgba(51, 51, 51, 1);
          font-weight: bold;
          margin-bottom: 8px;
        }
        .option {
          font-size: 18px;
          color: rgba(102, 102, 102, 1);
          padding-left: 16px;
          position: relative;
          line-height: 3;
          &::before {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
            border-radius: 50%;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      .right {
        width: 480px;
      }
    }
  }
}
</style>
