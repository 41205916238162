<template>
  <div class="custom-select">
    <div class="selected-option" @click="toggleOptions">
      <span :class="{ isPlaceHolder: selectedLabel === placeholder }">{{ selectedLabel }}</span>
      <i class="arrow"></i>
    </div>
    <div class="options" v-show="showOptions">
      <div class="scroll-holder">
        <div class="scroll-content">
          <div class="o-li" v-for="(option, i) in options" :key="option.value" @click="selectOption(option, i)">
            {{ option.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'n-select',
  data() {
    return {
      selectedOption: null,
      showOptions: false
    };
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  computed: {
    selectedLabel() {
      return this.selectedOption ? this.selectedOption.label : this.placeholder;
    }
  },
  methods: {
    toggleOptions() {
      this.showOptions = !this.showOptions;
    },
    selectOption(option, i) {
      this.$emit('change', i);
      this.selectedOption = option;
      this.showOptions = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-select {
  position: relative;
  width: 224px;
}

.selected-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;
  border: 1px solid rgba(221, 221, 221, 1);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  height: 50px;
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  box-sizing: border-box;
}
.isPlaceHolder {
  color: rgba(153, 153, 153, 1);
}

.selected-option:hover {
  background-color: #f5f5f5;
}

.arrow {
  border: solid #999999;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
}

.options {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: 100%;
  max-height: 200px;

  list-style-type: none;
  padding: 0;
  margin: 0;
  z-index: 999;
  display: flex;
  &::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background-color: white;
    position: absolute;
    left: 15px;
    top: 0;
    transform: rotate(45deg) translateY(-50%);
  }
}
.scroll-holder {
  box-shadow: 2px 2px 5px #999;
  background-color: #ffffff;
  border-radius: 4px;
  height: 100%;
  overflow: hidden;
  max-height: 200px;
  flex: 1;
}
.scroll-content {
    overflow-y: auto;
    max-height: 200px;
    height: 100%;
    padding: 5px 0;
}

.options .o-li {
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s;
  z-index: 1;
  position: relative;
}

.options .o-li:hover {
  background-color: #f2f2f2;
  color: #4e99fd;
}
</style>
