import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '@/views/index.vue';
import getPlan from '@/views/getPlan.vue';
import plan from '@/views/plan.vue';
import productCenter from '@/views/productCenter.vue';
import officialVehicle from '@/views/officialVehicle.vue';
import logistics from '@/views/logistics.vue';
import carry from '@/views/carry.vue';
import danger from '@/views/danger.vue';
import wasteTruck from '@/views/wasteTruck.vue';

import Produce from '@/views/produce/index.vue';
import InternationalService from '@/views/international/index.vue';
import Cooperate from '@/views/cooperate/index.vue';
import About from '@/views/about/index.vue';
import NotFound from '../views/notFound.vue';

import CommercialConcreteTrucks from '@/views/plan-view/commercial-concrete-trucks/index.vue';
import ColdChain from '@/views/plan-view/cold-chain/index.vue';
import SchoolBus from '@/views/plan-view/school-bus/index.vue';
import Forklift from '@/views/plan-view/forklift/index.vue';
import FishingBoat from '@/views/plan-view/fishing-boat/index.vue';
import UrbanManage from '@/views/plan-view/urban-manage/index.vue';
import Sanitation from '@/views/plan-view/sanitation/index.vue';
import HighPrecisionLocate from '@/views/plan-view/high-precision-locate/index.vue';
import IndustrialParkVehicleManagementSystem from '@/views/plan-view/industrial-park-vehicle-management-system/index.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Index
  },
  {
    path: '/plan/wasteTruck',
    component: wasteTruck
  },
  {
    path: '/plan/danger',
    component: danger
  },
  {
    path: '/plan/carry',
    component: carry
  },
  {
    path: '/plan/logistics',
    component: logistics
  },
  {
    path: '/plan/officialVehicle',
    component: officialVehicle
  },
  {
    name: 'get-plan',
    path: '/getPlan',
    component: getPlan
  },
  {
    path: '/plan',
    component: plan
  },
  {
    path: '/productCenter',
    component: productCenter
  },
  {
    path: '/produce-center',
    component: Produce
  },
  {
    path: '/international-service',
    component: InternationalService
  },
  {
    path: '/cooperation-case',
    component: Cooperate
  },
  {
    path: '/about',
    component: About
  },
  //   {
  //     name: '404',
  //     path: '/404',
  //     component: NotFound
  //   },
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/plan/commercial-concrete-trucks',
    component: CommercialConcreteTrucks
  },
  {
    path: '/plan/cold-chain',
    component: ColdChain
  },
  {
    path: '/plan/school-bus',
    component: SchoolBus
  },
  {
    path: '/plan/forklift',
    component: Forklift
  },
  {
    path: '/plan/fishing-boat',
    component: FishingBoat
  },
  {
    path: '/plan/urban-manage',
    component: UrbanManage
  },
  {
    path: '/plan/sanitation',
    component: Sanitation
  },
  {
    path: '/plan/high-precision-locate',
    component: HighPrecisionLocate
  },
  {
    path: '/plan/industrial-park-vehicle-management-system',
    component: IndustrialParkVehicleManagementSystem
  }
];

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

let router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  next();
});

router.afterEach(() => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
});

export default router;
