import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import json from '../../public/data.json';
import utils from '../utils/utils';
import { usePromise } from '../hooks/index';

Vue.use(Vuex);

function parseJson(target) {
  Object.keys(target).forEach(key => {
    const val = target[key];
    if (typeof val === 'string' || typeof val === 'number') {
      target[key] = '';
    } else if (Array.isArray(val) || typeof val === 'object') {
      parseJson(val);
    }
  });
}

parseJson(json);

export default new Vuex.Store({
  state: {
    jsonStatus: usePromise(),
    dataJSON: null
  },
  getters: {
    globalData(state) {
      const data = state.dataJSON;
      if (data) {
        return data;
      } else {
        return json;
      }
    },
    dataReady(state) {
      return state.jsonStatus[0];
    }
  },
  mutations: {
    setBannerIndex(state, payload) {
      state.bannerIndex = payload;
    },
    setBannerChildIndex(state, payload) {
      state.bannerChildIndex = payload;
    },
    setdataJSON(state, payload) {
      state.dataJSON = payload;
      state.jsonStatus[1]();
    }
  },
  actions: {
    async fetchData(context) {
      const data = await axios.get(window.origin + (process.env.BASE_URL || '/') + 'data.json');
      const str = JSON.stringify(data.data);
      const res = utils.replaceTemplate(str);
      context.commit('setdataJSON', JSON.parse(res) || {});
    }
  },
  strict: process.env.NODE_ENV !== 'production'
});
