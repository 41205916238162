<template>
  <div class="full-container">
    <top-nav :banner="globalData.danger.banner" isParent> </top-nav>

    <div class="industry content-limit">
      <block-title
        :en="globalData.danger.industry.enTitle"
        :title="globalData.danger.industry.chTitle"
        :sub="globalData.danger.industry.chBrief"
      ></block-title>
      <div class="tabs">
        <div
          class="item"
          :class="{ active: industryCurrentIndex === index }"
          v-for="(item, index) of globalData.danger.industry.list"
          :key="index"
          @click="industryCurrentIndex = index"
        >
          {{ item.title }}
        </div>
      </div>
      <div
        class="content"
        v-for="(item, index) of globalData.danger.industry.list"
        :key="index"
        v-if="industryCurrentIndex === index"
      >
        <img class="img" :src="item.img" alt="" />
        <div class="hover">
          <div class="title">
            <img
              class="icon"
              src="/static-images/officialVehicle/img_1.png"
              alt=""
            />
            {{ item.title }}
          </div>
          <div class="brief">{{ item.brief }}</div>
        </div>
      </div>
    </div>

    <div
      class="plan"
      :style="{
        background: `url(${globalData.danger.plan.bg}) no-repeat center/cover`,
      }"
    >
      <block-title
        :en="globalData.danger.plan.enTitle"
        :title="globalData.danger.plan.chTitle"
        :sub="globalData.danger.plan.chBrief"
      ></block-title>
      <div class="list content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.danger.plan.list"
          :key="index"
        >
          <img class="icon" :src="item.img" alt="" />
          <div class="title">{{ item.title }}</div>
          <div class="brief">{{ item.brief }}</div>
        </div>
      </div>
    </div>

    <div class="scene">
      <block-title
        :en="globalData.danger.scene.enTitle"
        :title="globalData.danger.scene.chTitle"
        :sub="globalData.danger.scene.chBrief"
      ></block-title>
      <div class="tabs content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.danger.scene.list1"
          :key="'0' + index"
          :class="{ active: sceneCurrentIndex1 === index }"
          @click="sceneCurrentIndex1 = index"
        >
          {{ item.tab }}
        </div>
      </div>
      <div
        class="content content-limit"
        v-for="(item, index) of globalData.danger.scene.list1"
        :key="'0' + index"
        v-if="sceneCurrentIndex1 === index"
      >
        <img class="left" :src="item.img" alt="" />
        <div class="right">
          <div class="title">{{ item.title }}</div>
          <div class="brief">{{ item.brief }}</div>
          <div v-if="item.head">
            <div
              class="head"
              v-for="(iitem, iindex) of item.head"
              :key="iindex"
            >
              <span class="sp">{{ iitem.head }}</span
              >：{{ iitem.body }}
            </div>
          </div>

          <div v-if="item.number">
            <div
              class="number sp"
              v-for="(iitem, iindex) of item.number"
              :key="iindex"
            >
              {{ iitem.body }}
            </div>
          </div>
        </div>
      </div>

      <div class="tabs content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.danger.scene.list2"
          :key="index"
          :class="{ active: sceneCurrentIndex2 === index }"
          @click="sceneCurrentIndex2 = index"
        >
          {{ item.title }}
        </div>
      </div>
      <div
        class="content content-limit"
        v-for="(item, index) of globalData.danger.scene.list2"
        :key="index"
        v-if="sceneCurrentIndex2 === index"
      >
        <img class="left" :src="item.img" alt="" />
        <div class="right">
          <div class="title">{{ item.title }}</div>
          <div class="brief">{{ item.brief }}</div>
          <div v-if="item.head">
            <div
              class="head"
              v-for="(iitem, iindex) of item.head"
              :key="iindex"
            >
              <span class="sp">{{ iitem.head }}</span
              >：{{ iitem.body }}
            </div>
          </div>

          <div v-if="item.number">
            <div
              class="number sp"
              v-for="(iitem, iindex) of item.number"
              :key="iindex"
            >
              {{ iitem.body }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <product-list coloredbg></product-list>

    <customer-wall></customer-wall>
    <footer-resolution></footer-resolution>
    <my-footer></my-footer>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import contactUs from "./index-view/contact-us.vue";
import customerWall from "./index-view/customer-wall.vue";
import supportService from "./index-view/support-service.vue";
import honorsWall from "./index-view/honors-wall.vue";
import productCenter from "./index-view/product-center.vue";

import displayicon1 from "../asset/index/csb_index_22.png";
import displayicon2 from "../asset/index/csb_index_23.png";
import displayicon3 from "../asset/index/csb_index_24.png";
import displayicon4 from "../asset/index/csb_index_25.png";

let plans = [];

export default {
  components: {
    contactUs,
    customerWall,
    supportService,
    honorsWall,
    productCenter,
  },
  data() {
    return {
      sceneCurrentIndex1: 0,
      sceneCurrentIndex2: 0,
      industryCurrentIndex: 0,

      resCurrentIndex: 0,
      display: [displayicon1, displayicon2, displayicon3, displayicon4],

      applyCurrentIndex: 0,
      abCurrentIndex: 0,
      driveCurrentIndex: 0,
      swiperCurrentIndex: 0,
      tabIndex: 0,
    };
  },
  methods: {
    switchTabs(index) {
      this.tabIndex = index;
    },
    next() {
      if (this.swiperCurrentIndex < this.swiper?.length - 1) {
        this.swiperCurrentIndex++;
      } else {
        this.swiperCurrentIndex = 0;
      }
    },
    sendInfo() {
      this.$router.push({
        name: "get-plan",
        params: {
          name: this.$refs.name.value,
          mobile: this.$refs.phone.value,
          plan: plans[this.applyCurrentIndex],
        },
      });
    },
  },
  computed: {
    ...mapGetters(["globalData"]),
  },
  mounted() {
    // plans = this.globalData.index.apply.tabs.map((v) => {
    //   return v.txt;
    // });
  },
  destroyed() {},
};
</script>

<style scoped lang="scss">
.tech {
  padding: 80px 0;
  background: url(@/asset/index/csb_index_30.png) no-repeat center/cover;
  .list {
    margin-top: 80px;
    .item {
      display: flex;
      align-items: center;
      gap: 100px;
      padding: 100px;
      background: white;
      &:nth-child(2n) {
        background: transparent;
        flex-direction: row-reverse;
      }
      .info {
        flex-grow: 1;
        .en {
          font-size: 18px;
          color: #dddddd;
        }
        .title {
          font-size: 32px;
          color: #333333;
          font-weight: bold;
          margin-top: 8px;
        }
        .brief {
          font-size: 18px;
          color: #666666;
          line-height: 2;
          margin-top: 40px;
        }
      }
      .img {
        flex-shrink: 0;
        width: 460px;
      }
    }
  }
}
.scene {
  padding: 80px 0;
  .tabs {
    display: flex;
    align-items: center;
    box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.16);
    border-radius: 8px 8px 8px 8px;
    padding: 4px;
    height: 60px;
    box-sizing: border-box;
    margin-bottom: 24px;
    margin-top: 80px;
    .item {
      border-radius: 8px;
      color: rgba(102, 102, 102, 1);
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      height: 100%;
      cursor: pointer;
    }
    .active {
      background: linear-gradient(180deg, #4e99fd 0%, #3384e8 100%);
      color: white;
    }
  }
  .content {
    display: flex;
    gap: 24px;
    height: 580px;
    .left {
      width: 845px;
      height: 580px;
      flex-shrink: 0;
    }
    .right {
      height: 100%;
      flex-grow: 1;
      padding: 24px;
      box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.16);
      border-radius: 8px 8px 8px 8px;
      overflow-y: scroll;
      box-sizing: border-box;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
      }
      &::-webkit-scrollbar-track {
        background: #e7e7e7;
      }
      .title {
        color: rgba(51, 51, 51, 1);
        font-size: 28px;
        line-height: 2;
      }
      .brief {
        color: rgba(102, 102, 102, 1);
        font-size: 18px;
        line-height: 2;
        margin-bottom: 16px;
      }
      .sp {
        color: rgba(9, 109, 217, 1);
      }
      .head {
        line-height: 2;
        font-size: 14px;
        color: rgba(153, 153, 153, 1);
      }
      .number {
        line-height: 2;
        font-size: 14px;
      }
    }
  }
}

.industry {
  padding: 100px 0 80px;
  .tabs {
    display: flex;
    align-items: center;
    background: linear-gradient(122deg, #ffffff 0%, #f3f8ff 100%);
    box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.16);
    border-radius: 8px 8px 8px 8px;
    margin-bottom: 16px;
    margin-top: 80px;
    .item {
      font-size: 20px;
      color: #666666;
      flex: 1;
      cursor: pointer;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .active {
      color: white;
      background: linear-gradient(180deg, #4e99fd 0%, #3384e8 100%);
      border-radius: 8px 8px 8px 8px;
    }
  }
  .content {
    position: relative;
    font-size: 0;
    width: 100%;
    height: 400px;
    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .hover {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      text-align: center;
      background: linear-gradient(
        to top,
        #ffffff 0%,
        rgba(255, 255, 255, 0.1) 100%
      );
      box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.16);
      border-radius: 8px 8px 8px 8px;
      padding: 20px 0;
      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-size: 28px;
        color: #333333;
        .icon {
          width: 28px;
        }
      }
      .brief {
        color: rgba(102, 102, 102, 0.7);
        font-size: 18px;
        margin-top: 16px;
      }
    }
  }
}

.plan {
  padding: 80px 0;
  .list {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: 60px;
    .item {
      border-radius: 4px 4px 4px 4px;
      padding: 20px 30px 30px;
      flex: 1;
      height: 244px;
      box-sizing: border-box;
      border: 1px solid;
      text-align: center;
      border-image: linear-gradient(
          180deg,
          rgba(78.00000295042992, 153.00000607967377, 253.0000001192093, 1),
          rgba(84.00000259280205, 130.0000074505806, 250.00000029802322, 1)
        )
        1 1;
      .icon {
        width: 60px;
      }
      .title {
        color: rgba(51, 51, 51, 1);
        font-size: 24px;
        margin-top: 8px;
        margin-bottom: 12px;
      }
      .brief {
        font-size: 16px;
        color: rgba(51, 51, 51, 0.7);
        line-height: 2;
      }
    }
  }
}
</style>
