var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"full-container"},[_c('top-nav',{attrs:{"color":"#333333","banner":_vm.globalData.wasteTruck.banner,"isParent":""}}),_vm._v(" "),_c('div',{staticClass:"system"},[_c('block-title',{attrs:{"en":_vm.globalData.wasteTruck.system.enTitle,"title":_vm.globalData.wasteTruck.system.chTitle,"sub":_vm.globalData.wasteTruck.system.chBrief}}),_vm._v(" "),_c('div',{staticClass:"content",style:({
        background: `url(${_vm.globalData.wasteTruck.system.centerBg}) no-repeat center/2.08333rem`
      })},[_c('div',{staticClass:"row row1"},_vm._l((_vm.globalData.wasteTruck.system.row[0]),function(innerItem,innerIndex){return _c('div',{key:innerIndex,staticClass:"item"},[_c('img',{staticClass:"icon",attrs:{"src":innerItem.icon,"alt":""}}),_vm._v("\n          "+_vm._s(innerItem.txt)+"\n        ")])}),0),_vm._v(" "),_c('div',{staticClass:"row row2"},_vm._l((_vm.globalData.wasteTruck.system.row[1]),function(innerItem,innerIndex){return _c('div',{key:innerIndex,staticClass:"item"},[_c('img',{staticClass:"icon",attrs:{"src":innerItem.icon,"alt":""}}),_vm._v("\n          "+_vm._s(innerItem.txt)+"\n        ")])}),0),_vm._v(" "),_c('div',{staticClass:"row row3"},_vm._l((_vm.globalData.wasteTruck.system.row[2]),function(innerItem,innerIndex){return _c('div',{key:innerIndex,staticClass:"item"},[_c('img',{staticClass:"icon",attrs:{"src":innerItem.icon,"alt":""}}),_vm._v("\n          "+_vm._s(innerItem.txt)+"\n        ")])}),0)])],1),_vm._v(" "),_c('div',{staticClass:"plan",style:({
      background: `url(${_vm.globalData.wasteTruck.plan.bg}) no-repeat center/cover`
    })},[_c('block-title',{attrs:{"en":_vm.globalData.wasteTruck.plan.enTitle,"title":_vm.globalData.wasteTruck.plan.chTitle,"sub":_vm.globalData.wasteTruck.plan.chBrief}}),_vm._v(" "),_c('div',{staticClass:"list content-limit"},_vm._l((_vm.globalData.wasteTruck.plan.list),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('img',{staticClass:"icon",attrs:{"src":item.img,"alt":""}}),_vm._v(" "),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_vm._v(" "),_c('div',{staticClass:"brief"},[_vm._v(_vm._s(item.brief))])])}),0)],1),_vm._v(" "),_c('div',{staticClass:"scene"},[_c('block-title',{attrs:{"en":_vm.globalData.wasteTruck.scene.enTitle,"title":_vm.globalData.wasteTruck.scene.chTitle,"sub":_vm.globalData.wasteTruck.scene.chBrief}}),_vm._v(" "),_c('div',{staticClass:"content",style:({
        background: `url(${_vm.globalData.wasteTruck.scene.centerBg}) no-repeat center/2.08333rem`
      })},[_c('div',{staticClass:"row row1"},_vm._l((_vm.globalData.wasteTruck.scene.row[0]),function(innerItem,innerIndex){return _c('div',{key:innerIndex,staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{staticClass:"icon",attrs:{"src":innerItem.icon,"alt":""}}),_vm._v("\n            "+_vm._s(innerItem.txt)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"brief"},[_vm._v(_vm._s(innerItem.brief))])])}),0),_vm._v(" "),_c('div',{staticClass:"row row2"},_vm._l((_vm.globalData.wasteTruck.scene.row[1]),function(innerItem,innerIndex){return _c('div',{key:innerIndex,staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{staticClass:"icon",attrs:{"src":innerItem.icon,"alt":""}}),_vm._v("\n            "+_vm._s(innerItem.txt)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"brief"},[_vm._v(_vm._s(innerItem.brief))])])}),0),_vm._v(" "),_c('div',{staticClass:"row row3"},_vm._l((_vm.globalData.wasteTruck.scene.row[2]),function(innerItem,innerIndex){return _c('div',{key:innerIndex,staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{staticClass:"icon",attrs:{"src":innerItem.icon,"alt":""}}),_vm._v("\n            "+_vm._s(innerItem.txt)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"brief"},[_vm._v(_vm._s(innerItem.brief))])])}),0)]),_vm._v(" "),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"item",staticStyle:{"margin":"0 auto"}},[_c('div',{staticClass:"top"},[_c('img',{staticClass:"icon",attrs:{"src":_vm.globalData.wasteTruck.scene.row[3].icon,"alt":""}}),_vm._v("\n          "+_vm._s(_vm.globalData.wasteTruck.scene.row[3].txt)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"brief"},[_vm._v("\n          "+_vm._s(_vm.globalData.wasteTruck.scene.row[3].brief)+"\n        ")])])]),_vm._v(" "),_c('img',{staticClass:"bottom-img",attrs:{"src":_vm.globalData.wasteTruck.scene.bottomImg,"alt":""}})],1),_vm._v(" "),_c('div',{staticClass:"vehicle",style:({
      background: `url(${_vm.globalData.wasteTruck.vehicle.bg}) no-repeat center/cover`
    })},[_c('block-title',{attrs:{"en":_vm.globalData.wasteTruck.vehicle.enTitle,"title":_vm.globalData.wasteTruck.vehicle.chTitle,"sub":_vm.globalData.wasteTruck.vehicle.chBrief}}),_vm._v(" "),_c('div',{staticClass:"list content-limit"},_vm._l((_vm.globalData.wasteTruck.vehicle.list),function(item,index){return _c('div',{key:index,staticClass:"item hover-scale"},[_c('div',{staticClass:"hover-holder"},[_c('img',{staticClass:"img hover-target",attrs:{"src":item.img,"alt":""}})]),_vm._v(" "),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_vm._v(" "),_c('div',{staticClass:"brief"},[_vm._v(_vm._s(item.brief))])])}),0)],1),_vm._v(" "),_c('product-list'),_vm._v(" "),_c('customer-wall'),_vm._v(" "),_c('footer-resolution'),_vm._v(" "),_c('my-footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }