function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import NSelect from '@/components/n-select/n-select.vue';
export default {
  name: 'footer-resolution',
  components: {
    NSelect: NSelect
  },
  data: function data() {
    return {
      carType: [{
        label: '商砼车',
        value: '商砼车'
      }, {
        label: '公务车',
        value: '公务车'
      }, {
        label: '物流车',
        value: '物流车'
      }, {
        label: '押运车客车',
        value: '押运车客车'
      }, {
        label: '危险品车',
        value: '危险品车'
      }, {
        label: '环卫车',
        value: '环卫车'
      }, {
        label: '执法车',
        value: '执法车'
      }, {
        label: '渔船',
        value: '渔船'
      }, {
        label: '渣土车',
        value: '渣土车'
      }, {
        label: '叉车',
        value: '叉车'
      }, {
        label: '校车',
        value: '校车'
      }, {
        label: '冷链车',
        value: '冷链车'
      }, {
        label: '滴滴车',
        value: '滴滴车'
      }],
      type: null,
      name: '',
      phone: '',
      company: ''
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['globalData'])), {}, {
    footer: function footer() {
      return this.globalData.common.footer;
    },
    getStyle: function getStyle() {
      return {
        background: "url(".concat(this.footer.resolution.background, ") center center / 110% 110% no-repeat")
      };
    }
  }),
  methods: {
    sendInfo: function sendInfo() {
      this.$router.push({
        name: 'get-plan',
        params: {
          plan: this.type,
          name: this.name,
          mobile: this.phone,
          company: this.company
        }
      });
    },
    onTypeChange: function onTypeChange(i) {
      this.type = this.carType[i].label;
    },
    submit: function submit() {
      var info = {
        type: this.type,
        name: this.name,
        phone: this.phone,
        company: this.company
      };
      if (!info.name || !info.phone) {
        alert('请填写姓名和手机号');
        return;
      }
      this.sendInfo();
    }
  }
};