var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"full-container"},[_c('top-nav',{attrs:{"banner":_vm.globalData.carry.banner,"isParent":""}}),_vm._v(" "),_c('div',{staticClass:"pain py-80px"},[_c('div',{staticClass:"block-content"},[_c('block-title',{attrs:{"en":_vm.pain.title.en,"title":_vm.pain.title.value,"sub":_vm.pain.title.sub}}),_vm._v(" "),_c('div',{staticClass:"card-container flex justify-between mt-60px gap-32px"},_vm._l((_vm.pain.list),function(t){return _c('div',{staticClass:"card-item flex-col w-405px bg-#f2f7fd p-40px"},[_c('img',{staticClass:"wh-32px",attrs:{"src":t.icon,"alt":""}}),_vm._v(" "),_c('div',{staticClass:"title text-#333 text-24px font-bold mt-32px"},[_vm._v("\n            "+_vm._s(t.title)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"value text-#333 opacity-70 text-16px mt-18px line-height-28px"},[_vm._v("\n            "+_vm._s(t.description)+"\n          ")])])}),0)],1)]),_vm._v(" "),_c('div',{staticClass:"plan",style:({
      background: `url(${_vm.globalData.carry.plan.bg}) no-repeat center/cover`,
    })},[_c('block-title',{attrs:{"en":_vm.globalData.carry.plan.enTitle,"title":_vm.globalData.carry.plan.chTitle,"sub":_vm.globalData.carry.plan.chBrief}}),_vm._v(" "),_c('div',{staticClass:"list content-limit"},_vm._l((_vm.globalData.carry.plan.list),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('img',{staticClass:"icon",attrs:{"src":item.img,"alt":""}}),_vm._v(" "),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_vm._v(" "),_c('div',{staticClass:"brief"},[_vm._v(_vm._s(item.brief))])])}),0)],1),_vm._v(" "),_c('div',{staticClass:"industry content-limit"},[_c('block-title',{attrs:{"en":_vm.globalData.carry.industry.enTitle,"title":_vm.globalData.carry.industry.chTitle,"sub":_vm.globalData.carry.industry.chBrief}}),_vm._v(" "),_c('div',{staticClass:"tabs"},_vm._l((_vm.globalData.carry.industry.list),function(item,index){return _c('div',{key:index,staticClass:"item",class:{ active: _vm.industryCurrentIndex === index },on:{"click":function($event){_vm.industryCurrentIndex = index}}},[_vm._v("\n        "+_vm._s(item.title)+"\n      ")])}),0),_vm._v(" "),_vm._l((_vm.globalData.carry.industry.list),function(item,index){return (_vm.industryCurrentIndex === index)?_c('div',{key:index,staticClass:"content"},[_c('img',{staticClass:"img",attrs:{"src":item.img,"alt":""}}),_vm._v(" "),_c('div',{staticClass:"hover"},[_c('div',{staticClass:"title"},[_c('img',{staticClass:"icon",attrs:{"src":"/static-images/carry/img_9.png","alt":""}}),_vm._v("\n          "+_vm._s(item.title)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"brief"},[_vm._v(_vm._s(item.brief))])])]):_vm._e()})],2),_vm._v(" "),_c('div',{staticClass:"resolution",style:({
      background: `url(${_vm.globalData.carry.plan.bg}) no-repeat center/cover`,
    })},[_c('block-title',{attrs:{"en":_vm.globalData.carry.application.enTitle,"title":_vm.globalData.carry.application.chTitle,"sub":_vm.globalData.carry.application.chBrief}}),_vm._v(" "),_c('div',{staticClass:"content content-limit",staticStyle:{"margin-top":"60px"}},[_c('div',{staticClass:"left"},_vm._l((_vm.globalData.carry.application.list),function(item,index){return _c('div',{key:index,staticClass:"item",class:{ 'active-item': index === _vm.resCurrentIndex }},[_c('div',{staticClass:"top",on:{"click":function($event){_vm.resCurrentIndex = index}}},[(_vm.resCurrentIndex !== index)?_c('img',{staticClass:"icon",attrs:{"src":item.icon,"alt":""}}):_vm._e(),_vm._v(" "),(_vm.resCurrentIndex === index)?_c('img',{staticClass:"icon",attrs:{"src":item.activeIcon,"alt":""}}):_vm._e(),_vm._v("\n            "+_vm._s(item.title)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"bottom"},[_vm._v("\n            "+_vm._s(item.brief)+"\n          ")])])}),0),_vm._v(" "),_c('div',{staticClass:"right"},[(_vm.globalData.carry.application.list[_vm.resCurrentIndex].isVideo)?_c('video',{staticClass:"img",attrs:{"autoplay":"","src":_vm.globalData.carry.application.list[_vm.resCurrentIndex].img}}):_c('img',{staticClass:"img",attrs:{"src":_vm.globalData.carry.application.list[_vm.resCurrentIndex].img,"alt":""}}),_vm._v(" "),_c('div',{staticClass:"feature"},_vm._l((_vm.globalData.carry.application.list[
              _vm.resCurrentIndex
            ].feature),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/asset/index/csb_index_61.png"),"alt":""}}),_vm._v("\n            "+_vm._s(item)+"\n          ")])}),0)])])],1),_vm._v(" "),_c('div',{staticClass:"feature"},[_c('div',{staticClass:"list content-limit"},_vm._l((_vm.globalData.carry.feature.list),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"en"},[_vm._v(_vm._s(item.enTitle.toUpperCase()))]),_vm._v(" "),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_vm._v(" "),_c('div',{staticClass:"brief"},[_vm._v(_vm._s(item.brief))]),_vm._v(" "),(item.options)?_c('div',_vm._l((item.options),function(iitem,iindex){return _c('div',{key:iindex,staticClass:"option"},[_vm._v("\n              "+_vm._s(iitem)+"\n            ")])}),0):_vm._e()]),_vm._v(" "),_c('img',{staticClass:"right",attrs:{"src":item.img,"alt":""}})])}),0)]),_vm._v(" "),_c('product-list',{attrs:{"coloredbg":""}}),_vm._v(" "),_c('customer-wall'),_vm._v(" "),_c('footer-resolution'),_vm._v(" "),_c('my-footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }