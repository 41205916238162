<template>
  <div class="full-container">
    <top-nav :banner="globalData.productCenter.banner" isParent> </top-nav>

    <product-list :index.sync="pIndex" noTitle :limited="false"></product-list>

    <customer-wall></customer-wall>
    <footer-resolution></footer-resolution>
    <my-footer></my-footer>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import contactUs from "./index-view/contact-us.vue";
import customerWall from "./index-view/customer-wall.vue";
import supportService from "./index-view/support-service.vue";
import honorsWall from "./index-view/honors-wall.vue";
import productCenter from "./index-view/product-center.vue";

import displayicon1 from "../asset/index/csb_index_22.png";
import displayicon2 from "../asset/index/csb_index_23.png";
import displayicon3 from "../asset/index/csb_index_24.png";
import displayicon4 from "../asset/index/csb_index_25.png";

let plans = [];

export default {
  components: {
    contactUs,
    customerWall,
    supportService,
    honorsWall,
    productCenter,
  },
  data() {
    return {
      pIndex: 0,

      resCurrentIndex: 0,
      display: [displayicon1, displayicon2, displayicon3, displayicon4],

      applyCurrentIndex: 0,
      abCurrentIndex: 0,
      driveCurrentIndex: 0,
      swiperCurrentIndex: 0,
      tabIndex: 0,
    };
  },
  methods: {
    switchTabs(index) {
      this.tabIndex = index;
    },
    next() {
      if (this.swiperCurrentIndex < this.swiper?.length - 1) {
        this.swiperCurrentIndex++;
      } else {
        this.swiperCurrentIndex = 0;
      }
    },
    sendInfo() {
      this.$router.push({
        name: "get-plan",
        params: {
          name: this.$refs.name.value,
          mobile: this.$refs.phone.value,
          plan: plans[this.applyCurrentIndex],
        },
      });
    },
  },
  computed: {
    ...mapGetters(["globalData"]),
  },
  mounted() {
    let tabIndex = 0;
    if (this.$route.query.i !== undefined) {
      tabIndex = Number(this.$route.query.i);
    }
    this.pIndex = tabIndex;
  },
  beforeRouteUpdate(to, from, next) {
    let tabIndex = 0;
    if (to.query.i !== undefined) {
      tabIndex = Number(to.query.i);
    }
    this.pIndex = tabIndex;
    next();
  },
  destroyed() {},
};
</script>

<style scoped lang="scss">
.display {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 60px 0 80px;
  .item {
    box-shadow: 3px 3px 8px 0px #e8e8e8;
    border-radius: 2px 2px 2px 2px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    line-height: 1;
    padding: 24px 0;
    .icon {
      width: 32px;
    }
    .info {
      .title {
        margin-bottom: 20px;
        color: #333333;
        font-size: 24px;
      }
      .brief {
        color: #666666;
        font-size: 14px;
      }
    }
  }
}
.about {
  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    background: url(@/asset/index/csb_index_29.png) no-repeat center/cover;
    height: 530px;
    line-height: 1;
    .en {
      font-size: 48px;
      color: rgba(255, 255, 255, 0.4);
    }
    .title {
      font-size: 48px;
      color: white;
      font-family: YouSheBiaoTiHei;
    }
  }
  .bottom {
    background: url(@/asset/index/csb_index_27.png) no-repeat -30px 20px/510px,
      url(@/asset/index/csb_index_27.png) no-repeat right -100px bottom / 510px;
    display: flex;
    padding-bottom: 30px;

    .content {
      width: 1080px;
      margin: 0 auto;
      padding: 50px 80px;
      border-radius: 20px;
      margin-top: -100px;
      background: white;
      box-sizing: border-box;
      .digit {
        display: flex;
        align-items: center;
        .item {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1;
          gap: 16px;
          &:first-child ~ .item {
            border-left: 1px solid #c4c4c4;
          }
          .icon {
            width: 60px;
          }
          .info {
            .name {
              font-size: 16px;
              color: #282938;
              margin-bottom: 10px;
            }
            .number {
              font-size: 36px;
              color: #096dd9;
            }
          }
        }
      }
      .title {
        font-size: 24px;
        color: #666666;
        margin-top: 58px;
        margin-bottom: 16px;
      }
      .txt {
        font-size: 16px;
        color: #666666;
        line-height: 2;
      }
      .strip {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;
        color: #455a64;
        font-size: 52px;
        background-color: rgba(196, 196, 196, 0.2);
        padding: 45px 0;
        margin-top: 30px;
        .icon {
          width: 48px;
        }
      }
    }
  }
}
.tech {
  padding: 80px 0;
  background: url(@/asset/index/csb_index_30.png) no-repeat center/cover;
  .list {
    margin-top: 80px;
    .item {
      display: flex;
      align-items: center;
      gap: 100px;
      padding: 100px;
      background: white;
      &:nth-child(2n) {
        background: transparent;
        flex-direction: row-reverse;
      }
      .info {
        flex-grow: 1;
        .en {
          font-size: 18px;
          color: #dddddd;
        }
        .title {
          font-size: 32px;
          color: #333333;
          font-weight: bold;
          margin-top: 8px;
        }
        .brief {
          font-size: 18px;
          color: #666666;
          line-height: 2;
          margin-top: 40px;
        }
      }
      .img {
        flex-shrink: 0;
        width: 460px;
      }
    }
  }
}
.prod-display {
  padding: 80px 140px 0;

  .prod-tabs {
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .prod-item {
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 24px;
      color: #666666;
      cursor: pointer;

      .prod-icon {
        height: 32px;
        margin-right: 16px;
      }

      .prod-txt {
        padding: 0 5px;
        box-sizing: border-box;
      }

      &:hover {
        color: #096dd9;
      }
    }

    .prod-active {
      color: #096dd9;
      flex-wrap: wrap;

      .prod-icon {
        height: 80px;
        margin-right: 0;
      }

      .prod-txt {
        width: 100%;
        margin-top: 16px;
      }
    }
  }

  .prod-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    // justify-content: space-between;
    margin-top: 80px;

    .prod-item {
      width: 33%;
      box-sizing: border-box;
      padding: 20px;

      .prod-inner {
        position: relative;
        cursor: pointer;
        box-shadow: 0 0 10px 0 #eee;

        .prod-img {
          width: 100%;
          height: 440px;
        }

        .prod-hover {
          position: absolute;
          border-radius: 8px;
          width: calc(100% - 32px);
          left: 16px;
          bottom: 16px;
          font-size: 20px;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1.5;
          transition: all 0.5s;
          font-size: 18px;
          color: #666666;

          .prod-innerTxt {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        &:hover {
          .prod-hover {
            opacity: 0.8;
            height: 40%;
            background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
            color: white;
            font-size: 20px;

            .prod-innerTxt {
              white-space: normal;
            }
          }
        }
      }
    }
  }
}
.product {
  padding: 100px 0 80px;
}
.prod-button {
  font-size: 24px;
  color: #fffdfd;
  background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
  border-radius: 2px 2px 2px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  margin: 0 auto;
  margin-top: 40px;
  width: 217px;
  cursor: pointer;
  gap: 12px;
  .icon {
    width: 30px;
  }
}
.issue {
  background: url(@/asset/index/csb_index_35.png) no-repeat center/cover;
  padding: 80px 0 140px;
  .center {
    position: relative;
    margin: 0 auto;
    width: 795px;
    margin-top: 140px;
    .bg {
      width: 100%;
    }
    .item {
      position: absolute;
      text-align: center;
      white-space: nowrap;
      .title {
        font-size: 24px;
        color: #333333;
        line-height: 1.5;
      }
      .brief {
        font-size: 14px;
        color: #666666;
        line-height: 2;
      }
    }
    .issue1 {
      left: 0;
      top: -15px;
    }
    .issue2 {
      right: 0;
      top: -15px;
    }
    .issue3 {
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
    .issue4 {
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
    .issue5 {
      left: 0;
      bottom: -32px;
    }
    .issue6 {
      right: 0;
      bottom: -32px;
    }
  }
}
.resolution {
  padding: 80px 0 100px;
  .content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 55px;
    margin-top: 80px;
  }
  .left {
    width: 4px;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(78, 153, 253, 0.05) 0%,
      #4e99fd 55%,
      rgba(84, 130, 250, 0.05) 100%
    );
    position: relative;
    padding: 24px 0;
    .item {
      width: 432px;
      margin-left: -16px;
      line-height: 1;
      max-height: 32px;
      overflow: hidden;
      transition: all 0.3s;
      &:first-child ~ .item {
        margin-top: 40px;
      }
      .top {
        display: flex;
        align-items: center;
        gap: 24px;
        font-size: 20px;
        color: #6f8cab;
        cursor: pointer;
        .icon {
          width: 32px;
        }
      }
      .bottom {
        padding-left: 56px;
        padding-top: 20px;
        font-size: 20px;
        color: #666666;
        line-height: 2;
      }
    }
    .active-item {
      transition: all 0.5s;
      max-height: 500px;
      .top {
        color: #096dd9;
        font-weight: bold;
      }
      .bottom {
      }
    }
  }
  .right {
    margin-top: 24px;
    width: 737px;
    background: linear-gradient(
      180deg,
      rgba(9, 109, 217, 0.1) 0%,
      rgba(9, 109, 217, 0) 100%
    );
    border-radius: 8px 8px 8px 8px;
    padding: 24px;
    box-sizing: border-box;
    .img {
      width: 100%;
    }
    .feature {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        font-size: 24px;
        color: rgba(102, 102, 102, 1);
        margin-top: 40px;
        .icon {
          width: 24px;
        }
      }
    }
  }
}
</style>
