import { render, staticRenderFns } from "./environment.vue?vue&type=template&id=04196399&scoped=true"
import script from "./environment.vue?vue&type=script&lang=js"
export * from "./environment.vue?vue&type=script&lang=js"
import style0 from "./environment.vue?vue&type=style&index=0&id=04196399&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_cdufxcupg4dwprz6d7p4gluelm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04196399",
  null
  
)

export default component.exports