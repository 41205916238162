<template>
  <div class="title-switcher p-4px flex-center">
    <div class="option flex-1 flex-center" v-for="t in list" @click="whenChange(t)" :class="{ checked: t.value === innerCurrent }">{{ t.label }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      innerCurrent: null
    };
  },
  props: {
    current: 0,
    list: {
      type: Array
    }
  },
  watch: {
    current: {
      handler(value) {
        this.innerCurrent = value;
      },
      immediate: true
    }
  },
  created() {
    if (this.list.length && !this._current) {
      this.innerCurrent = this.list[0].value;
    }
  },
  methods: {
    whenChange(target) {
      this.innerCurrent = target.value;
      this.$emit('change', target.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.title-switcher {
  box-sizing: border-box;
  height: 60px;
  background: linear-gradient(122deg, #ffffff 0%, #f3f8ff 100%);
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.16);
  border-radius: 8px 8px 8px 8px;
  * {
    box-sizing: border-box;
  }
  .option {
    height: 100%;
    cursor: pointer;
    font-family: Source Han Sans, Source Han Sans;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    color: #666666;
    transition: all 0.2s;
    border-radius: 8px 8px 8px 8px;
  }
  .option.checked {
    background: linear-gradient(180deg, #4e99fd 0%, #3384e8 100%);
    color: #ffffff;
  }
}
</style>
