var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{ref:"banner",staticClass:"nav",class:{ white: !_vm.color, parent: _vm.isParent, navheight: _vm.navOnly },on:{"mouseenter":_vm.stopCount,"mouseleave":_vm.startCount}},[_c('div',{staticClass:"nav-container"},[(!_vm.navOnly)?_c('div',{staticClass:"banner-list"},_vm._l((_vm.banner),function(item,index){return _c('div',{key:index,staticClass:"banner-item",style:({
            transform: `translateX(${(index - _vm.currentIndex) * 100}%)`,
            background: `url(${item.img}) no-repeat center/cover`
          })},[_c('div',{staticClass:"banner-limit"},[_c('div',{staticClass:"banner-item-title banner-top",style:({ color: item.color ? item.color : _vm.color })},[_vm._v("\n              "+_vm._s(item.txt)+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"banner-item-brief",style:({ color: _vm.color || item.color ? 'rgba(51, 51, 51, .8)' : '' }),domProps:{"innerHTML":_vm._s(item.brief)}}),_vm._v(" "),_c('div',{staticClass:"banner-buttons"},[_c('div',{staticClass:"banner-button banner-button1",class:{ 'banner-button1-c': _vm.color || item.colored },style:({
                  color: item.lbc,
                  'border-color': item.lbc
                }),on:{"click":_vm.getPlan}},[_vm._v("\n                获取方案\n              ")]),_vm._v(" "),_c('div',{staticClass:"banner-button banner-button2",class:{ 'banner-button2-c': _vm.color || item.colored },style:({
                  color: item.rbc
                }),on:{"click":_vm.chat}},[_vm._v("\n                立即咨询\n              ")])])])])}),0):_vm._e(),_vm._v(" "),_c('div',{staticClass:"content",class:{ scrolling: _vm.getDown }},[_c('div',{staticClass:"content-wrap"},[_c('div',{staticClass:"content-inner"},[_c('img',{staticClass:"logo",attrs:{"src":_vm.color || _vm.getDown ? _vm.logo : _vm.whiteLogo,"alt":""}}),_vm._v(" "),_c('div',{staticClass:"tabs"},[_c('div',{staticClass:"query"},[(_vm.showNav)?_c('div',{staticClass:"list"},_vm._l((_vm.globalData.common.navs),function(item,index){return _c('div',{key:index,staticClass:"item",class:{ active: _vm.bannerIndex == index }},[_c('div',{ref:"tabs",refInFor:true,staticClass:"inner",on:{"click":function($event){return _vm.nav(index)}}},[_vm._v("\n                      "+_vm._s(item.name)+"\n                    ")]),_vm._v(" "),(typeof item.route === 'object')?_c('div',{staticClass:"child",class:{ 'child-sp': item.sp }},[_c('div',{staticClass:"child-inner"},[_c('div',{staticClass:"child-content"},_vm._l((item.route),function(outerItem,outerIndex){return _c('div',{key:outerIndex,staticClass:"child-list"},[_c('div',{staticClass:"child-parent",class:{
                                'child-active': _vm.bannerChildIndex == outerIndex
                              },on:{"mousedown":function($event){return _vm.parentNav(index, outerIndex)}}},[_c('img',{staticClass:"child-icon",attrs:{"src":item.sp ? _vm.centericon[outerIndex] : _vm.navicon[outerIndex],"alt":""}}),_vm._v("\n                              "+_vm._s(outerItem.name)+"\n                            ")])])}),0)])]):_vm._e()])}),0):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"phone-content"},[_c('div',{staticClass:"phone"},[_vm._v("\n                  全国服务热线\n                  "),_c('img',{staticClass:"phone-icon sale-ani",attrs:{"src":_vm.color || _vm.getDown ? _vm.phone : _vm.whitePhone,"alt":""}}),_vm._v(" "),_c('div',{staticClass:"phone-num"},[_vm._v(_vm._s(_vm.globalData.common.navPhone))])])])])])])]),_vm._v(" "),(_vm.banner.length > 1)?_c('div',{staticClass:"indicator"},_vm._l((_vm.banner),function(item,index){return _c('div',{key:index,staticClass:"indicator-item",class:{ 'indicator-item-active': _vm.currentIndex === index },on:{"click":function($event){return _vm.setCurrentIndex(index)}}})}),0):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }