export function usePromise() {
  const temp = {
    promise: null,
    resolve: null,
    reject: null
  };
  temp.promise = new Promise((resolve, reject) => {
    temp.resolve = resolve;
    temp.reject = reject;
  });
  return [temp.promise, temp.resolve, temp.reject];
}
