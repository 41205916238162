<template>
  <div class="footer-container">
    <div class="block-content">
      <div class="peculiarity w-full">
        <div class="fp-container" v-for="t in footer.peculiarity">
          <div class="grid-box-1">
            <div class="grid-area-a wh-32px flex-col justify-start">
              <img class="object-contain wh-full" :src="t.icon" alt="" />
            </div>
            <div class="grid-area-b t-title-18">{{ t.title }}</div>
            <div class="grid-area-c t-value-16 opacity-50">{{ t.description }}</div>
          </div>
        </div>
      </div>

      <div class="flex justify-between gap-60px">
        <div class="left flex-col pt-40px gap-40px">
          <div class="flex flex-y-center" v-for="t in footer.contact">
            <div class="grid-box-1">
              <div class="grid-area-a wh-32px flex-col justify-start">
                <img class="object-contain wh-full transform-none!" :src="t.icon" alt="" />
              </div>
              <div class="grid-area-b t-title-18 font-bold! whitespace-break-spaces line-height-30px!">{{ t.title }}</div>
              <div class="grid-area-c t-value-16 opacity-50">{{ t.description }}</div>
            </div>
          </div>

          <div class="contact-source flex gap-30px">
            <div class="flex-col gap-16px flex-center" v-for="t in footer.contactSource">
              <img class="wh-80px" :src="t.icon" alt="" />
              <span class="text-14px text-white">{{ t.title }}</span>
            </div>
          </div>
        </div>
        <div class="right flex justify-end gap-180px flex-1 pt-40px">
          <div class="nav-container" v-for="t in navs">
            <a :href="resolveHref(t)" class="nav-title text-20px text-white cursor-pointer">{{ t.name }}</a>
            <div class="child-container flex gap-20px">
              <div class="child-nav flex-col mt-40px gap-24px" v-for="m in getSliced(t.route, 7, t)">
                <a :href="resolveHref(r)" class="t-value-16 opacity-50 cursor-pointer" v-for="r in m">{{ r.name }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-box">
      <span>{{ globalData.common.footer.copyright }}</span>
      <a class="copyright" target="_blank" href="https://beian.miit.gov.cn/">
        <img src="@/asset/common/images/beian.png" alt="" />
        {{ globalData.common.footer.record }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['globalData']),
    list() {
      return this.globalData?.common?.navs?.find?.(t => t.name === '解决方案')?.route;
    },
    footer() {
      return this.globalData.common.footer;
    },
    navs() {
      try {
        const navs = JSON.parse(JSON.stringify(this.globalData.common.navs));
        const product = navs.find(t => t.name === '产品中心');
        const plan = navs.find(t => t.name === '解决方案');
        const custom = navs.find(t => t.name === '关于我们');
        custom.path = custom.route;
        custom.route = navs
          .filter(t => {
            return !['产品中心', '解决方案'].some(e => e === t.name);
          })
          .map(t => {
            return {
              name: t.name,
              path: t.route
            };
          });

        const result = [product, plan, custom];

        return result;
      } catch (e) {
        return [];
      }
    },
    route() {
      return this.$route.path;
    }
  },
  methods: {
    goTo(route) {
      const source = route;
      route = String(route);
      if (route === this.route) {
        return false;
      } else if (Array.isArray(source)) {
        return this.goTo(source[0].path);
      } else if (route.startsWith('/')) {
        return this.$router.push(route);
      } else if (route.startsWith('http')) {
        window.open(route);
      }
    },
    isSelected(item) {
      if (Array.isArray(item.route)) {
        return item.route.some(each => each.path === this.route);
      } else {
        return item.route === this.route;
      }
    },
    getSliced(arr, size, t) {
      if (!arr || !arr.length || !Array.isArray(arr)) {
        return [];
      }
      const res = arr.reduce((acc, cur, idx) => {
        if (idx % size === 0) {
          acc.push([cur]);
        } else {
          acc[acc.length - 1].push(cur);
        }
        return acc;
      }, []);
      return res;
    },
    resolveHref(route) {
      const href = route.path || route.route;
      if (!href) return 'javascript:void 0';
      if (!href) {
        return '/#/';
      }
      if (Array.isArray(href)) {
        return `/#${href[0].path || '/'}`;
      } else {
        return `/#${href.route || href || '/'}`;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.footer-container {
  width: 100%;
  background-color: #172539;

  .peculiarity {
    border-bottom: 1px solid rgba(white, 0.2);
    display: flex;
    justify-content: space-between;
  }
  .fp-container {
    height: 137px;
    display: flex;
    align-items: center;
  }
  .grid-box-1 {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
    row-gap: 16px;
    column-gap: 8px;
    grid-template-areas: 'a b' 'a c';
    img {
      transform: translateY(-6px);
    }
  }

  .copyright-box {
    width: 100%;
    margin-top: 40px;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 14px;
    background-color: #0f1825;
    color: rgba(255, 255, 255, 0.4);
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 0;

    img {
      width: 20px;
      /* no */
      height: 22px;
      /* no */
    }
  }

  .copyright {
    margin-left: 30px;
    color: #707070;
    display: inline-flex;
    align-items: center;
    column-gap: 5px;
  }

  .copyright:hover {
    color: gray;
  }
  a:hover {
    color: white;
    opacity: 1;
  }
}
</style>
