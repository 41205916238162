<template>
  <div class="full-container">
    <top-nav :banner="globalData.logistics.banner" isParent> </top-nav>

    <div class="pain py-80px">
      <div class="block-content">
        <block-title
          :en="pain.title.en"
          :title="pain.title.value"
          :sub="pain.title.sub"
        ></block-title>

        <div class="card-container flex justify-between mt-60px gap-32px">
          <div
            class="card-item flex-col w-405px bg-#f2f7fd p-40px"
            v-for="t in pain.list"
          >
            <img class="wh-32px" :src="t.icon" alt="" />
            <div class="title text-#333 text-24px font-bold mt-32px">
              {{ t.title }}
            </div>
            <div
              class="value text-#333 opacity-70 text-16px mt-18px line-height-28px"
            >
              {{ t.description }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tech">
      <block-title
        :en="globalData.logistics.tech.enTitle"
        :title="globalData.logistics.tech.chTitle"
        :sub="globalData.logistics.tech.chBrief"
      ></block-title>
      <div class="list content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.logistics.tech.list"
          :key="index"
        >
          <div class="info">
            <div class="en">{{ item.en.toUpperCase() }}</div>
            <div class="title">{{ item.title }}</div>
            <div class="brief">{{ item.brief }}</div>
          </div>
          <img class="img" :src="item.img" alt="" />
        </div>
      </div>
    </div>

    <div class="scene">
      <block-title
        :en="globalData.logistics.scene.enTitle"
        :title="globalData.logistics.scene.chTitle"
        :sub="globalData.logistics.scene.chBrief"
      ></block-title>
      <div class="tabs content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.logistics.scene.list1"
          :key="index"
          :class="{ active: sceneCurrentIndex1 === index }"
          @click="sceneCurrentIndex1 = index"
        >
          {{ item.title }}
        </div>
      </div>
      <div
        class="content content-limit"
        v-for="(item, index) of globalData.logistics.scene.list1"
        :key="index"
        v-if="sceneCurrentIndex1 === index"
      >
        <video
          autoplay
          v-if="item.isVideo"
          class="left"
          :src="item.img"
        ></video>
        <img v-else class="left" :src="item.img" alt="" />
        <div class="right">
          <div class="title">{{ item.title }}</div>
          <div class="brief">{{ item.brief }}</div>
          <div v-if="item.head">
            <div
              class="head"
              v-for="(iitem, iindex) of item.head"
              :key="iindex"
            >
              <span class="sp">{{ iitem.head }}</span
              >：{{ iitem.body }}
            </div>
          </div>

          <div v-if="item.number">
            <div
              class="number sp"
              v-for="(iitem, iindex) of item.number"
              :key="iindex"
            >
              {{ iitem.body }}
            </div>
          </div>
        </div>
      </div>

      <div class="tabs content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.logistics.scene.list2"
          :key="index"
          :class="{ active: sceneCurrentIndex2 === index }"
          @click="sceneCurrentIndex2 = index"
        >
          {{ item.title }}
        </div>
      </div>
      <div
        class="content content-limit"
        v-for="(item, index) of globalData.logistics.scene.list2"
        :key="index"
        v-if="sceneCurrentIndex2 === index"
      >
        <video
          autoplay
          class="left"
          v-if="item.isVideo"
          :src="item.img"
        ></video>
        <img v-else class="left" :src="item.img" alt="" />
        <div class="right">
          <div class="title">{{ item.title }}</div>
          <div class="brief">{{ item.brief }}</div>
          <div v-if="item.head">
            <div
              class="head"
              v-for="(iitem, iindex) of item.head"
              :key="iindex"
            >
              <span class="sp">{{ iitem.head }}</span
              >：{{ iitem.body }}
            </div>
          </div>

          <div v-if="item.number">
            <div
              class="number sp"
              v-for="(iitem, iindex) of item.number"
              :key="iindex"
            >
              {{ iitem.body }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <product-list coloredbg></product-list>

    <customer-wall></customer-wall>
    <footer-resolution></footer-resolution>
    <my-footer></my-footer>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import contactUs from "./index-view/contact-us.vue";
import customerWall from "./index-view/customer-wall.vue";
import supportService from "./index-view/support-service.vue";
import honorsWall from "./index-view/honors-wall.vue";
import productCenter from "./index-view/product-center.vue";

import displayicon1 from "../asset/index/csb_index_22.png";
import displayicon2 from "../asset/index/csb_index_23.png";
import displayicon3 from "../asset/index/csb_index_24.png";
import displayicon4 from "../asset/index/csb_index_25.png";

let plans = [];

export default {
  components: {
    contactUs,
    customerWall,
    supportService,
    honorsWall,
    productCenter,
  },
  data() {
    return {
      sceneCurrentIndex1: 0,
      sceneCurrentIndex2: 0,
      industryCurrentIndex: 0,

      resCurrentIndex: 0,
      display: [displayicon1, displayicon2, displayicon3, displayicon4],

      applyCurrentIndex: 0,
      abCurrentIndex: 0,
      driveCurrentIndex: 0,
      swiperCurrentIndex: 0,
      tabIndex: 0,
    };
  },
  methods: {
    switchTabs(index) {
      this.tabIndex = index;
    },
    next() {
      if (this.swiperCurrentIndex < this.swiper?.length - 1) {
        this.swiperCurrentIndex++;
      } else {
        this.swiperCurrentIndex = 0;
      }
    },
    sendInfo() {
      this.$router.push({
        name: "get-plan",
        params: {
          name: this.$refs.name.value,
          mobile: this.$refs.phone.value,
          plan: plans[this.applyCurrentIndex],
        },
      });
    },
  },
  computed: {
    ...mapGetters(["globalData"]),
    pain() {
      return this.globalData["logistics"].pain;
    },
  },
  mounted() {
    // plans = this.globalData.index.apply.tabs.map((v) => {
    //   return v.txt;
    // });
  },
  destroyed() {},
};
</script>

<style scoped lang="scss">
.tech {
  padding: 80px 0;
  background: url(@/asset/index/csb_index_30.png) no-repeat center/cover;
  .list {
    margin-top: 80px;
    .item {
      display: flex;
      align-items: center;
      gap: 100px;
      padding: 100px;
      background: white;
      &:nth-child(2n) {
        background: transparent;
        flex-direction: row-reverse;
      }
      .info {
        flex-grow: 1;
        .en {
          font-size: 18px;
          color: #dddddd;
        }
        .title {
          font-size: 32px;
          color: #333333;
          font-weight: bold;
          margin-top: 8px;
        }
        .brief {
          font-size: 18px;
          color: #666666;
          line-height: 2;
          margin-top: 40px;
        }
      }
      .img {
        flex-shrink: 0;
        width: 460px;
      }
    }
  }
}
.scene {
  padding: 80px 0;
  .tabs {
    display: flex;
    align-items: center;
    box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.16);
    border-radius: 8px 8px 8px 8px;
    padding: 4px;
    height: 60px;
    box-sizing: border-box;
    margin-bottom: 24px;
    margin-top: 80px;
    .item {
      border-radius: 8px;
      color: rgba(102, 102, 102, 1);
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      height: 100%;
      cursor: pointer;
    }
    .active {
      background: linear-gradient(180deg, #4e99fd 0%, #3384e8 100%);
      color: white;
    }
  }
  .content {
    display: flex;
    gap: 24px;
    height: 580px;
    .left {
      width: 845px;
      height: 580px;
      flex-shrink: 0;
    }
    .right {
      height: 100%;
      flex-grow: 1;
      padding: 24px;
      box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.16);
      border-radius: 8px 8px 8px 8px;
      overflow-y: scroll;
      box-sizing: border-box;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
      }
      &::-webkit-scrollbar-track {
        background: #e7e7e7;
      }
      .title {
        color: rgba(51, 51, 51, 1);
        font-size: 28px;
        line-height: 1.5;
      }
      .brief {
        color: rgba(102, 102, 102, 1);
        font-size: 18px;
        line-height: 1.5;
        margin-bottom: 16px;
      }
      .sp {
        color: rgba(9, 109, 217, 1);
      }
      .head {
        line-height: 2;
        font-size: 14px;
        color: rgba(153, 153, 153, 1);
      }
      .number {
        line-height: 2;
        font-size: 14px;
      }
    }
  }
}
</style>
