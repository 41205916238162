<template>
  <div id="root">
    <router-view></router-view>
    <scroll-to-top></scroll-to-top>
    <get-plan-toast></get-plan-toast>
  </div>
</template>

<script>
import GetPlanToast from '@/components/get-plan-toast/index.vue';
export default {
  components: {
    GetPlanToast
  },
  mounted() {
    this.$store.dispatch('fetchData');
  }
};
</script>

<style>
body {
  overflow-x: hidden;
}

.hideTxt {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#root {
  margin: 0 auto;
}
.content-limit {
  max-width: 1280px;
  margin: 0 auto;
}
</style>
<style lang="scss">
.right {
  .feature {
    .item {
      justify-content: flex-start !important;
      padding: 0 10px;
      box-sizing: border-box;
    }
  }
}
.combine {
  .inner {
    .item {
      justify-content: flex-start !important;
      padding: 0 10px;
      box-sizing: border-box;
    }
  }
}
</style>
