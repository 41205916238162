<template>
  <div class="partner z-0">
    <block-title :en="partner.en" :title="partner.value" :sub="partner.sub"></block-title>
    <div class="p-container">
      <div class="list content-limit mt-66px!">
        <div class="item pi1" v-for="(item, index) of partner1" :key="index">
          <img :src="item" alt="" />
        </div>
      </div>
      <div class="list content-limit">
        <div class="item pi2" v-for="(item, index) of partner2" :key="index">
          <img :src="item" alt="" />
        </div>
      </div>
      <div class="list content-limit">
        <div class="item pi1" v-for="(item, index) of partner3" :key="index">
          <img class="img" :src="item" alt="" />
        </div>
      </div>
      <div class="list content-limit">
        <div class="item pi2" v-for="(item, index) of partner4" :key="index">
          <img class="img" :src="item" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js';
import partic1 from './asset/index_20.png';
import partic2 from './asset/index_71.png';
import partic3 from './asset/index_12.png';
import partic5 from './asset/index_13.png';
import partic6 from './asset/index_14.png';
import partic7 from './asset/index_15.png';
import partic8 from './asset/index_16.png';
import partic9 from './asset/index_17.png';
import partic10 from './asset/index_18.png';
import partic11 from './asset/index_10.png';
import partic12 from './asset/index_24.png';
import partic13 from './asset/index_23.png';
import partic14 from './asset/index_22.png';
import partic15 from './asset/index_21.png';

export default {
  data() {
    return {
      partner1: [partic1, partic2, partic3, partic5, partic6, partic8, partic13],
      partner2: [partic7, partic9, partic10, partic1, partic14, partic11, partic2],
      partner3: [partic14, partic11, partic13, partic15, partic9, partic5, partic1],
      partner4: [partic15, partic8, partic6, partic2, partic12, partic7, partic3]
    };
  },
  computed: {
    globalData() {
      return this.$store.getters.globalData;
    },
    partner() {
      return this.globalData.index.partner;
    }
  },
  mounted() {
    let xTrans = [];
    anime.set('.partner .pi1', {
      translateX: function (el, i, l) {
        xTrans[i] = { x: i * 228 };
        return i * 228;
      }
    });

    window.ani = anime({
      targets: xTrans,
      duration: 35000, //走一周持续时间
      easing: 'linear',
      x: '+=1596',
      loop: true,
      update: function (anim) {
        anime.set('.partner .pi1', {
          translateX: function (el, i, l) {
            return (xTrans[i].x % 1596) - 228;
          }
        });
      }
    });
    // 走马灯2;
    let xTranss = [];
    anime.set('.partner .pi2', {
      translateX: function (el, i, l) {
        xTranss[i] = { xx: i * 228 };
        return i * 228;
      }
    });

    anime({
      targets: xTranss,
      duration: 35000, //走一周持续时间
      easing: 'linear',
      xx: '+=1596',
      loop: true,
      update: function (anim) {
        anime.set('.partner .pi2', {
          translateX: function (el, i, l) {
            return (xTranss[i].xx % 1596) - 114 - 228;
          }
        });
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.partner {
  padding: 80px 0;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: calc(((100% - 1440px) / 2) + 300px);
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(to right, white, rgba(255, 255, 255, 0.2));
    z-index: 9;
  }
  &::after {
    content: '';
    position: absolute;
    width: calc(((100% - 1440px) / 2) + 300px);
    height: 100%;
    right: 0;
    top: 0;
    background: linear-gradient(to left, white, rgba(255, 255, 255, 0.2));
    z-index: 9;
  }
  .list {
    position: relative;
    margin-top: 30px;
    overflow: hidden;
    height: 146px;
    .item {
      position: absolute;
      width: 228px;
      height: 146px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .button {
    color: white;
    font-size: 24px;
    width: 217px;
    height: 61px;
    margin: 0 auto;
    margin-top: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
    .icon {
      width: 30px;
    }
  }

  .p-container {
    mask: radial-gradient(circle at center, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)); 
  }
}
</style>
