<template>
  <div name="fade-transition">
    <div class="hover-menu">
      <div class="hover-menu-item hover-menu-phone">
        <svg xmlns="http://www.w3.org/2000/svg" class="sale-ani" fill="currentColor" width="24.354" height="31.74" viewBox="0 0 24.354 31.74">
          <g transform="translate(-170.76 -76.207)">
            <path
              class="a"
              d="M174.818,86.9c-4.876,3.647-5.48,11.649-1.143,19.716,4.239,7.881,10.956,12.069,17.9,9.919,1.806-.558,3.538-2.105,3.538-3.847,0-3.028-3.456-9.058-6.046-9.526a5.438,5.438,0,0,0-2.911.7q-.2.088-.416.185l-.325.15c-.318.148-.585.278-1.039.5l-.2.1-.009-.009q-.123-.12-.237-.249a12.954,12.954,0,0,1-1.261-1.781l-.129-.216a20.719,20.719,0,0,1-1.474-3.012l-.032-.087.825-.354c.171-.073.333-.146.5-.222l.481-.218.37-.173a6.068,6.068,0,0,0,1.807-1.108c1.788-1.951-1.757-11.375-4.666-11.918a7.474,7.474,0,0,0-5.373,1.331l-.17.123Zm5.1.946c.371.07,1.454,1.746,2.267,3.907a17.076,17.076,0,0,1,.857,3.026l.024.149a5.019,5.019,0,0,1,.063.732l0,.112-.153.089-.084.047c-.269.146-.625.322-1.055.519l-.706.318c-.25.11-.5.22-.81.351l-1.12.478-.167.078-.056.03-.032-.032c-.067-.046-.125.118-.571,1.387.74,3.176,3.866,8.331,5.785,8.334l.237-.026a.674.674,0,0,0,.105-.019l.162-.058.139-.06.5-.244.854-.419c.132-.063.256-.122.395-.186l.39-.177.186-.082a10.825,10.825,0,0,1,1.14-.433l.136-.038c.164-.044.262-.06.237-.065,1.218.22,4.042,5.146,4.042,7.123,0,.415-.918,1.235-1.821,1.515-5.639,1.745-11.292-1.779-15.039-8.746-3.82-7.1-3.316-13.779.455-16.6a5.124,5.124,0,0,1,3.64-1.011Z"
              transform="translate(0 -9.147)"
            />
          </g>
        </svg>
        <div class="hover-menu-txt">电话咨询</div>
        <div class="hover-menu-phone-hide">{{ globalData.common.navPhone }}</div>
      </div>
      <div class="hover-menu-item hover-menu-chat">
        <svg xmlns="http://www.w3.org/2000/svg" class="heart-ani" fill="currentColor" width="30" height="29.781" viewBox="0 0 30 29.781">
          <path
            class="a"
            d="M136.919,124.595h-1.365v-.507a10.28,10.28,0,0,0-20.56,0v.507h-1.324a3.232,3.232,0,0,0-3.379,3.379v5.8a3.384,3.384,0,0,0,3.379,3.379h1.74v.009a4.758,4.758,0,0,0,4.75,4.75h1.58a1.831,1.831,0,0,0,1.822,1.681h3.48a1.832,1.832,0,0,0,1.827-1.827v-1.178a1.832,1.832,0,0,0-1.827-1.827h-3.48a1.835,1.835,0,0,0-1.822,1.69h-1.58a3.292,3.292,0,0,1-3.288-3.288v-.329l.032-.032a.572.572,0,0,0,.05-.059s0-.009.009-.014a.557.557,0,0,0,.046-.064s0,0,0-.009c.014-.023.027-.041.041-.064,0,0,0,0,0-.009.014-.023.023-.046.037-.073,0,0,0-.009,0-.014.009-.023.018-.05.027-.073a.016.016,0,0,1,0-.009.389.389,0,0,0,.023-.078v0a.533.533,0,0,0,.018-.082.017.017,0,0,1,0-.014.5.5,0,0,0,.009-.082v-.014c0-.027,0-.059,0-.087V124.088a8.083,8.083,0,0,1,16.167,0v11.965a1.093,1.093,0,0,0,1.1,1.1h2.457a3.383,3.383,0,0,0,3.379-3.379v-5.8a3.22,3.22,0,0,0-3.37-3.379Zm-24.437,9.175v-5.8a1.063,1.063,0,0,1,1.187-1.187H115v8.17h-1.329a1.188,1.188,0,0,1-1.187-1.187Zm25.625,0a1.191,1.191,0,0,1-1.187,1.187h-1.365v-8.17h1.365a1.063,1.063,0,0,1,1.187,1.187v5.8Z"
            transform="translate(-110.29 -113.808)"
          />
        </svg>
        <div class="hover-menu-txt">在线咨询</div>
      </div>
      <div class="hover-menu-item hover-menu-project" @click="$router.push('/getPlan')" >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          width="26.001"
          height="32.501"
          viewBox="0 0 26.001 32.501"
        >
          <g transform="translate(-170.667 -85.333)">
            <path
              class="a"
              d="M183.667,85.333a13,13,0,0,0-6.5,24.258v2.149a1.217,1.217,0,0,0,1.219,1.219h10.563a1.217,1.217,0,0,0,1.219-1.219v-2.149a13,13,0,0,0-6.5-24.258Zm4.737,22.442a1.182,1.182,0,0,0-.626.772c-.008.032-.016.065-.024.1s-.012.081-.016.122-.008.085-.008.126v1.625H179.6V108.9c0-.041,0-.085-.008-.126s-.008-.081-.016-.122a1.2,1.2,0,0,0-.65-.873,10.563,10.563,0,1,1,9.474,0Z"
              transform="translate(0)"
            />
            <path
              class="a"
              d="M351.085,403.2a1.219,1.219,0,0,1-.575-2.295,5.679,5.679,0,0,0,3.011-5.019,1.219,1.219,0,1,1,2.438,0,8.111,8.111,0,0,1-4.3,7.169,1.208,1.208,0,0,1-.572.144Zm2.03,12.188H342.552a1.219,1.219,0,0,1,0-2.438h10.563a1.219,1.219,0,0,1,0,2.438Z"
              transform="translate(-164.166 -297.552)"
            />
          </g>
        </svg>
        <div class="hover-menu-txt">获取方案</div>
      </div>
      <div class="hover-menu-item hover-menu-wechat">
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="30.649" height="27.584" viewBox="0 0 30.649 27.584">
          <path
            class="a"
            d="M109.846,148.383A9.2,9.2,0,0,1,114.9,161.4c-.064.12-.179.321-.348.609l.525,1.924a1.149,1.149,0,0,1-1.411,1.411l-1.927-.526-.224.132a9.2,9.2,0,0,1-11.719-1.924,12.665,12.665,0,0,1-2.034.164,12.494,12.494,0,0,1-6.374-1.735l-.49-.284L88,161.946a1.149,1.149,0,0,1-1.406-1.416l.783-2.829q-.294-.486-.571-.981a12.082,12.082,0,0,1-1.472-5.793,12.443,12.443,0,0,1,24.485-2.947,1.141,1.141,0,0,1,.028.4Zm-2.446-.5a10.149,10.149,0,0,0-19.768,3.045,9.782,9.782,0,0,0,1.192,4.694c.1.19.365.636.777,1.32a1.149,1.149,0,0,1,.123.9l-.378,1.365,1.409-.379a1.149,1.149,0,0,1,.87.113c.513.294.818.47.927.535a10.2,10.2,0,0,0,5.2,1.415q.332,0,.66-.021a9.2,9.2,0,0,1,8.371-13.005q.309,0,.612.02Zm4.8,14.261a1.149,1.149,0,0,1,.12-.888q.281-.468.547-.944a6.9,6.9,0,1,0-2.542,2.665c.079-.047.3-.175.665-.389a1.149,1.149,0,0,1,.881-.115l.451.123-.123-.451ZM94.145,149.011a1.532,1.532,0,1,1,1.532-1.532A1.532,1.532,0,0,1,94.145,149.011Zm6.9,0a1.532,1.532,0,1,1,1.532-1.532A1.532,1.532,0,0,1,101.041,149.011Zm3.065,7.279a1.149,1.149,0,1,1,1.149-1.149A1.149,1.149,0,0,1,104.106,156.29Zm5.364,0a1.149,1.149,0,1,1,1.149-1.149A1.149,1.149,0,0,1,109.469,156.29Z"
            transform="translate(-85.333 -138.667)"
          />
        </svg>
        <div class="hover-menu-txt">微信联系</div>
        <div class="hover-menu-wechat-hide"></div>
      </div>
      <div class="hover-menu-item hover-menu-top">
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="28.65" height="26.455" viewBox="0 0 28.65 26.455">
          <g transform="translate(-1716 -5508.167)">
            <g transform="translate(1716 5508.167)">
              <path
                class="a"
                d="M223.674,266.34a1.222,1.222,0,0,0-.619-.434,1.27,1.27,0,0,0-.433-.073h-.007l-.041,0a.563.563,0,0,0-.057,0,1.243,1.243,0,0,0-.417.069,1.17,1.17,0,0,0-.614.428l-9.064,8.172a.966.966,0,0,0-.328.728,1.151,1.151,0,0,0,.4.844,1.362,1.362,0,0,0,.909.348,1.223,1.223,0,0,0,.821-.3.389.389,0,0,1,.03-.027.321.321,0,0,0,.061-.057l6.975-6.264.014,15.071a.382.382,0,0,0,0,.092l0,.035a1.141,1.141,0,0,0,1.257,1.011,1.174,1.174,0,0,0,1.287-1.011l.013-15.2,6.966,6.255a.5.5,0,0,0,.074.067l.03.026a1.214,1.214,0,0,0,.819.3,1.362,1.362,0,0,0,.91-.348,1.037,1.037,0,0,0,.071-1.572Z"
                transform="translate(-208.25 -259.526)"
              />
              <path
                class="a"
                d="M119.813,129.979H93.384a1.031,1.031,0,1,0,0,2.056h26.429a1.031,1.031,0,1,0,0-2.056"
                transform="translate(-92.273 -129.979)"
              />
            </g>
          </g>
        </svg>
        <div class="hover-menu-txt">回到顶部</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
export default {
  name: 'scroll-to-top',
  data: function () {
    return {
      scrollTop: 0
    };
  },
  computed: {
    ...mapGetters(['globalData']),
    show() {
      const size = document.documentElement.clientHeight;
      return this.scrollTop > size;
    }
  },
  created() {
    this.$bus.$on('online-chat', this.onlineChat);
  },
  mounted() {
    // var project = document.querySelector(".hover-menu-project");
    var top = document.querySelector('.hover-menu-top');
    var wechat = document.querySelector('.hover-menu-wechat-hide');

    var qrcode = document.createElement('img');
    qrcode.src = 'https://zhbd-1256260408.cos.ap-chengdu.myqcloud.com/static/image/X3uAQjBh.jpg';
    qrcode.classList.add('hover-menu-wechat-qr');
    wechat.appendChild(qrcode);

    let onlineChat = document.querySelector('.hover-menu-chat');

    onlineChat.addEventListener('click', this.onlineChat);

    // project.addEventListener("click", (e) => {
    //   this.$router.push("/get-plan");
    // });
    top.addEventListener('click', () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    });
  },
  methods: {
    onlineChat() {
      this.$chat();
    }
  }
};
</script>

<style>
.hover-menu {
  border-radius: 8px 0px 0px 8px;
  position: fixed;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
}
.hover-menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  color: white;
  cursor: pointer;
  position: relative;
  background: white;
  border-radius: 50%;
  color: rgba(78, 153, 253, 1);
  margin-bottom: 16px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.1), 0px 8px 10px 1px rgba(0, 0, 0, 0.06), 0px 3px 14px 2px rgba(0, 0, 0, 0.05);
}
.hover-menu-item:hover {
  background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
  color: white;
}
.hover-menu-item svg{
  width: 24px;
}
.hover-menu-txt {
  font-size: 12px;
  margin-top: 8px;
}
.hover-menu-wechat:hover .hover-menu-wechat-hide {
  display: block;
}
.hover-menu-wechat-hide {
  display: none;
  position: absolute;
  width: 200%;
  right: 100%;
  top: 0;
  box-shadow: 0 0 10px 2px #ccc;
}
.hover-menu-wechat-qr {
  width: 100%;
}
.hover-menu-phone:hover .hover-menu-phone-hide {
  display: flex;
}
.hover-menu-phone-hide {
  background: white;
  display: none;
  font-size: 36px;
  color: #3295f0;
  position: absolute;
  right: 100%;
  height: 100%;
  top: 0;
  box-shadow: 0 0 10px 2px #ccc;
  white-space: nowrap;
  align-items: center;
  padding: 0 20px;
}
</style>
