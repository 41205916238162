import { render, staticRenderFns } from "./introduce-timeline.vue?vue&type=template&id=252b82cb&scoped=true"
import script from "./introduce-timeline.vue?vue&type=script&lang=js"
export * from "./introduce-timeline.vue?vue&type=script&lang=js"
import style0 from "./introduce-timeline.vue?vue&type=style&index=0&id=252b82cb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_cdufxcupg4dwprz6d7p4gluelm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "252b82cb",
  null
  
)

export default component.exports